import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios';
//全局注册字体
import "@/text/text.css";

import './assets/iconfont/iconfont.css'

import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

Vue.prototype.axios = axios

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue()

//加全局守卫,使用路由，当切换到新路由时，想要页面滚到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
