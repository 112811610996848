<template>
  <div class="hallo">
    <div class="videoContainer">
      <video
        class="fullscreenVideo"
        id="bgVid"
        playsinline=""
        autoplay=""
        muted=""
        loop=""
      >
        <source src="../assets/video/bacVideo.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="nav">
      <div class="logo">
        <img src="../assets/img/rtaLogo/rtaLogo.svg" alt="" />
      </div>
      <div class="Community">
        <a target="_blank" href="https://discord.gg/rta"
          ><img src="../assets/img/rtaLogo/discord.svg" alt=""
        /></a>
        <a target="_blank" href="https://www.youtube.com/@RTAAcademy">
          <img src="../assets/img/rtaLogo/youtube.svg" alt=""
        /></a>
        <a target="_blank" href="https://twitter.com/RtaAcademy">
          <img src="../assets/img/rtaLogo/twitter.svg" alt=""
        /></a>
        <a
          class="tradingview"
          target="_blank"
          href="https://cn.tradingview.com/u/RTA-Academy/"
        >
          <img src="../assets/img/rtaLogo/tradingview.svg" alt="" />
        </a>
        <a target="_blank" href="https://t.me/rtachannels"
          ><img src="../assets/img/rtaLogo/telegram.svg" alt=""
        /></a>
      </div>
    </div>
    <div class="footer">
      <div class="website">
        <a target="_blank" href="https://edu.rta-finance.com/">
          <div class="en">Academy</div>
          <div class="cn">学院官网</div></a
        >
        <a target="_blank" href="https://center.rta.academy/#/Home">
          <div class="en">Center</div>
          <div class="cn">实盘中心</div></a
        >
        <a target="_blank" href="https://rta.trade/">
          <div class="en">Data</div>
          <div class="cn">数据中心</div></a
        >
        <a target="_blank" href="https://lib.rta.academy/#/article?id=0">
          <div class="en">Library</div>
          <div class="cn">金融百科</div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.hallo {
  width: 100%;
  display: flex;
  flex-direction: column;
  .videoContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -100;
    #bgVid {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // height: 100vh;
      // width: auto;
      // min-width: 100%;
      // height: auto;
      // min-height: 100%;
      // outline: none;
    }
  }

  .videoContainer:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    // background: rgba(25, 29, 34, 0.65);
  }
  .nav {
    padding: 48px 60px;
    display: flex;
    justify-content: space-between;
    position: fixed;

    .Community {
      width: 100%;
      height: 100%;
      text-align: right;

      a {
        width: 20px;
        height: 20px;
        margin-left: 32px;
        overflow: hidden;
        text-decoration: none;
        img {
          width: 20px;
          height: 20px;
          position: relative;
          top: 0;
        }
      }
      a:hover img {
        top: -150px;
        filter: drop-shadow(0px 150px 0px #efc394);
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 60px;
    width: 100%;
    z-index: 9999;
    text-align: center;
    height: 60px;

    .website {
      height: 60px;
      width: 100%;

      display: flex;
      justify-content: center;
      a {
        width: 160px;
        height: 60px;
        background: rgba(51, 53, 62, 0.4);
        text-decoration: none;
        margin-right: 1px;
        display: flex;
        flex-direction: column;
        .en {
          height: 20px;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          margin-top: 12px;
        }
        .cn {
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
      a:hover {
        background: #efc394;

        .en {
          color: #33353e;
          font-size: 16px;
        }
        .cn {
          color: #33353e;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
