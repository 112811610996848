<template>
  <div id="app" v-if="isReload">
    <div v-if="$route.meta.showNav" class="nav" :style="{ backgroundColor: backgroundColor }">
      <div class="hre">
        <a class="logo" href="https://www.rta-finance.com/#/">
          <img src="@/assets/img/rtaLogo/venture.svg" alt="" />
        </a>
        <div class="Community">
          <div>
            <el-dropdown trigger="hover">
              <span class="el-dropdown-link">
                <a target="_blank" class="eduClass" @mouseover="showDiv" @mouseleave="hideDiv"
                  href="https://www.rta-finance.com/#/eduHome">教学中心</a>
              </span>
              <el-dropdown-menu slot="dropdown" class="Community-dropdown">
                <el-dropdown-item><a target="_blank" href="https://edu.rta-finance.com/">
                    教学站
                  </a></el-dropdown-item>
                <el-dropdown-item>
                  <a target="_blank" href="https://data.rta-finance.com">
                    数据站
                  </a></el-dropdown-item>
                <el-dropdown-item>
                  <a target="_blank" href="https://lib.rta-finance.com/#/">
                    百科站
                  </a></el-dropdown-item>
                <el-dropdown-item><a target="_blank" href="https://center.rta-finance.com/">
                    实盘站
                  </a></el-dropdown-item>
                <el-dropdown-item><a target="_blank" href="https://shop.rta-finance.com/">
                    商城站
                  </a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-dropdown trigger="hover">
              <span class="el-dropdown-link">
                <a target="_blank" class="ventClass" @mouseover="ventShowDiv" @mouseleave="ventHideDiv"
                  href="https://www.rta-finance.com/#/ventHome">
                  量化中心</a>
              </span>
              <el-dropdown-menu slot="dropdown" class="Community-dropdown Community-dropdown-v">
                <el-dropdown-item><a target="_blank" href="https://www.rta-finance.com/#/QuantitativeTrading">
                    交易平台
                  </a></el-dropdown-item>
                <el-dropdown-item>
                  <a target="_blank" href="https://www.rta-finance.com/#/AssetManagement">
                    资产平台
                  </a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <!-- <a target="_blank" href="https://edu.rta-finance.com/#"> 关于我们</a> -->
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
  //  console.log("1111",navigator);
  export default {
    name: "App",
    provide() {
      return {
        reload: this.reload,
        ifShow: this.ifShow,
      };
    },
    data() {
      return {
        isReload: true,
        backgroundColor: "",
        ifShow: false,
        displayDiv: false,
        ventShow: false,
      };
    },
    created() { },
    mounted() {
      // this.ifShow == true;
      window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    },
    methods: {
      //刷新不会出现空白页面
      reload() {
        this.isReload = false;

        this.$nextTick(() => {
          this.isReload = true;
        });
      },
      // 在方法中，添加这个handleScroll方法来获取滚动的位置
      handleScroll() {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // 设置背景颜色的透明读
        if (scrollTop) {
          this.backgroundColor = `rgba(51, 53, 62, 1)`;
        } else if (scrollTop === 0) {
          this.backgroundColor = "transparent";
        }
      },
      showDiv() {
        this.displayDiv = true;
      },
      hideDiv() {
        this.displayDiv = false;
      },
      ventShowDiv() {
        this.ventShow = true;
      },
      ventHideDiv() {
        this.ventShow = false;
      },
    },
  };
</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
    // width: 100vw;
  }

  .nav {
    width: 100%;
    // // height: 40px;
    // line-height: 40px;
    // background: rgba(51, 53, 62, 0.8);
    z-index: 10000;

    position: fixed;

    .hre {
      padding: 20px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: space-between;

      .logo {
        margin-left: 40px;
      }

      .Community {
        width: 100%;
        height: 100%;
        text-align: right;
        line-height: 29px;
        margin-right: 40px;
        display: flex;
        justify-content: flex-end;

        a {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          text-decoration: none;
          margin-left: 40px;
        }

        a:hover {
          color: #efc394;
        }
      }
    }
  }

  .nav :hover {
    background: rgba(51, 53, 62, 1);
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .el-popup-parent--hidden {
    padding: 0 !important;
  }

  body {
    margin: 0px !important;
  }

  .Community-dropdown {
    width: 100%;
    left: 0 !important;
    top: 58px !important;
    padding: 20px 0 40px 0 !important;
    margin: 5px 0;
    background-color: rgba(51, 53, 62, 1) !important;
    border: none !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

    .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background-color: transparent !important;
      color: #efc394 !important;
    }

    .el-dropdown-menu__item {
      list-style: none;
      line-height: 21px !important;
      margin: 0;
      color: #d1d4dc !important;
      outline: 0;
      display: flex !important;
      justify-content: flex-end !important;

      a {
        padding: 0 130px 0 0;
        margin: 0 auto;
        text-align: center;
        margin: 12px 0 0 0;
        text-decoration: none;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d1d4dc;
        line-height: 21px;
      }

      a:hover {
        color: #efc394;
      }
    }

    .popper__arrow {
      display: none !important;
    }
  }

  .Community-dropdown-v {
    .el-dropdown-menu__item {
      a {
        padding: 0 20px 0 0;
      }
    }
  }
</style>