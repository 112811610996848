import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/Home',
  //   name: 'home',
  //   children: [
  //     {
  //       path: '/Home',
  //       name: 'home',
  //       component: () => import('../components/entrance')
  //     }, {
  //       path: "/", redirect: "/Home"
  //     },
  //   ]
  // },
  {
    path: '/rebate',
    name: 'home',
    component: () => import('../components/rebate.vue'),

  },

  {
    path: '/',
    name: 'home',
    component: () => import('../components/entrance'),
    meta: {
      showNav: true
    },
    children: []

  },

  {
    path: '/eduContainer',
    name: 'education',
    component: () => import('../views/education/eduContainer.vue'),
    children: [
      {
        path: '/eduHome',
        name: 'edu首页',
        component: () => import('../views/education/eduHome.vue'),

      },
      {
        path: '/RebateService',
        name: '返佣服务',
        component: () => import('../views/education/rebateService/index.vue')
      },
      {
        path: '/ResearchReport',
        name: '研究报告',
        component: () => import('../views/education/researchReport/index.vue')
      },
      {
        path: '/CourseService',
        name: '课程服务',
        component: () => import('../views/education/courseService/index.vue')
      },
      {
        path: '/UseryStem',
        name: '用户体系',
        component: () => import('../views/education/useryStem/index.vue')
      },
      {
        path: '/ContactUs',
        name: '合作联系',
        component: () => import('../views/education/contactUs/index.vue')
      },
      {
        path: '/RecruitmentInformation',
        name: '职业机会',
        component: () => import('../views/education/recruitmentInformation/index.vue')
      },


    ]
  },
  {
    path: '/ventContainer',
    name: 'venture',
    component: () => import('../views/venture/ventContainer.vue'),
    children: [
      {
        path: '/ventHome',
        name: 'venture首页',
        component: () => import('../views/venture/ventHome.vue'),
        meta: {
          showNav: false
        }
      },
      {
        path: '/AboutUs',
        name: '关于我们',
        component: () => import('../views/venture/aboutUs')
      },
      {
        path: '/ProductDescription',
        name: '产品介绍',
        component: () => import('../views/venture/productDescription')
      },
      {
        path: '/QuantitativeTrading',
        name: '量化交易',
        component: () => import('../views/venture/quantitativeTrading')
      },
      {
        path: '/AssetManagement',
        name: '资产管理',
        component: () => import('../views/venture/assetManagement')
      },
      {
        path: '/QualifiedInvestor',
        name: '合格投资者',
        component: () => import('../views/venture/qualifiedInvestor')
      },

    ]
  },
  {
    path: '/recruitment',
    name: '招聘信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReInformation.vue')
  },


  {
    path: '/signUp',
    name: '收集信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/collectInformation')
  },

  {
    path: '/kLine',
    name: 'k线展示',
    component: () => import(/* webpackChunkName: "about" */ '../views/K/k-line.vue')
  },
]


const router = new VueRouter({
  //本页面刷新之后回到顶部，使用vue中的方法 scrollBehavior
  scrollBehavior(to, from, savedPosition) {
    //不同页面路由跳转之后回到顶部
    return { left: 0, top: 0 }
  },

  routes

})

export default router
